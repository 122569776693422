.App {
  text-align: center;
}

/* @import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Plus+Jakarta+Sans:wght@500&display=swap');

.ccenter{
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.pulse-lg{
  animation: pulse-lg 2s infinite;
}

.pulse-lg-2{
  animation: pulse-lg 2s 5;
}
.pulse{
  animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(247, 171, 84, 0.7) !important;
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(247, 171, 84, 0) !important;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(247, 171, 84, 0) !important;
	}
}

@keyframes pulse-lg {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(247, 171, 84, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(247, 171, 84, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(247, 171, 84, 0);
	}
}

/* .modal-backdrop {
  background-color: rgba(0, 128, 0, 0.801) !important;
} */
.my-modal .modal-content {
  background-color: rgba(255, 0, 0, 0) !important;
  border: none;
  box-shadow: none;
}
.playBtn{
  border-radius: 50%;
  background-color: var(--orange) ;
  height: 3rem;
  width: 3rem;
  animation: pulse 2s infinite;
}
.llegas{
  font-size: 2rem;
}
.lh-2 {
  line-height: 3rem !important;
}
.headerTitle {
  font-family: 'Dela Gothic One', sans-serif;
}
.italic{
  font-style: italic;
}
.w-auto{
  width: min-content !important;
}
.text-wrap{
  inline-size: 100% !important;
  overflow-wrap: break-word !important;
}
.animate__animated.animate__pulse {
  --animate-duration: 5s;
  animation-iteration-count: infinite;
}
.w-sm-100-140{
  width: 140%;
}
.br-30 {
  border-radius: 30px !important;
}
.topp-nav{
  z-index: 1041 !important;

}


.phone-input input{
  font-family: "Poppins", sans-serif;
  border: 1px solid #d8e2ef;
  border-radius: 5px;
  padding: 0 2rem 0 0.5rem;
  color: #2a3039;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  /* height: 2.1rem; */
}

.phone-input input::placeholder{
  font-family: "Poppins", sans-serif;
  color: #a4a7b5;
  text-transform: capitalize;
  font-weight: 470;
}
.ttop{
  z-index: 1040 !important;
}
.textArt{
  width: 115vw;
  overflow: hidden !important;
  font-size: 3.4rem !important;
  font-weight: 600 !important;
}
.text-fav{
  width:fit-content;
  font-size: 1rem;
  font-weight: 450;
  background-color: #EEFFEF;
  color: #49B14D;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.br-15 {
  border-radius: 14px !important;
}
.menuIcon{
  height: 7.5rem;
  width: 7.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brb-15{
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
.fh-lg {
  height: auto;
}

.leftMenu {
  position: relative;
}

.hvr-warning:hover>.fav-heart {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hvr-warning:hover>.fav-heart>.h-icon {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor {
  cursor: pointer !important;
}
/* 
.homeCar2 {
  background-image: url(../src/assets/img/background/banner_mariiachi.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  border-radius: 15px;
} */

.carousel-indicators {
  padding: 2rem;
}

.carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: none;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: var(--orange);
  height: 10px;
  width: 30px
}
.orange{
  box-shadow: none !important;
  border: 1px solid var(--orange) !important;
  color: white !important;
  background-color: var(--orange) !important;
}
.orange:hover{
  box-shadow: none !important;
  border: 1px solid var(--orange) !important;
}
.orange:hover::before{
  border: 1px solid var(--orange) !important;
}
.orange:hover::after{
  border: 1px solid var(--orange) !important;
}
.outline-orange{
  box-shadow: none !important;
  border: 1px solid var(--orange) !important;
  background-color: white !important;
  color:  var(--orange) !important;
  
}
.outline-none{
  outline: none !important;
  text-decoration: none !important;
}
.carousel-indicators button {
  outline: none !important;
  text-decoration: none !important;
  margin-left: 10px;
  margin-right: 1px;
  border-top: unset;
  border-bottom: unset;
  border: none !important;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: #f68e1e42;
  height: 10px;
  width: 30px
}

.text-location {
  color: #F68D1E;
  font-weight: 600;
}

.border-orange {
  border: 1px solid #f68e1e82 !important;
}
.btnChevron{
  height: 2rem;
  width: 2rem;
  border-radius: 50% !important;
}
.rbtn {
  background-color: #F68D1E !important;
  letter-spacing: normal;
  text-transform: none !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-radius: 10px !important;
}


.btn-pink {
  background-color: var(--danger) !important;
  color: white !important;
  font-size: 1rem !important;
  width: min-content;
  text-transform: none !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.h-f{
  height: min-content;
}
.arIcon{
  background-color: #F68D1E !important;
  width: min-content;
  border-radius: 10px;
  /* padding: 0.5rem; */
  font-size: 90%;
  height: 2.1rem;
  width: 2.1rem;
}
.btn-share{
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  color: #F68D1E !important;
  background-color: #FFEBD7 !important;
  font-size: 1rem !important;
  /* width: min-content; */
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  text-transform: none !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.btn-share:hover{
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  background-color: #fddfc1 !important;

}
.btn-orange {
  background-color: #F68D1E !important;
  color: white !important;
  font-size: 1rem !important;
  width: min-content;
  text-transform: none !important;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.btn-orange:hover {
  border: 1px solid #F68D1E !important;
}

.lbtn {
  letter-spacing: normal;
  text-transform: none !important;
  border: 2px solid #F68D1E !important;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px !important;
}

.waveIcon {
  color: white;
  background-color: var(--orange);
  border: 1px solid white;
  font-size: 1.3rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.blurBg2 {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  padding: 1rem;
  padding-top: 1.5rem !important;
  text-align: left;
  font-family: 'Dela Gothic One', cursive;
  height: 7.8rem;
  width: 17rem;
  border-radius: 30px;
  background-color: #00000043;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 30%;
  right: -5%;
  z-index: 3 !important;
}

.artistImg{
  height: 11rem;
  width: 100%;
  margin-top: 0;
  margin-left: 0;
  min-height: 11rem;
  object-fit: cover;
}
.videoProfile{
  height: 3rem;
  width: 3rem;
}
.guitarArtist {
  padding: 1rem;
  overflow: visible !important;
  height: 13rem;
  width: 9rem;
  border-radius: 30px;
  background-image: url(../src/assets/img/bgStage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 9%;
  right: -2%;
  z-index: 1;
}
.guitarArtist2 {
  padding: 1rem;
  overflow: visible !important;
  height: 13rem;
  width: 9rem;
  border-radius: 30px;
  background-image: url(../src/assets/img/bgStage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 14%;
  left: -4%;
  z-index: 1;
}

.sombreroLanding {
  padding: 1rem;
  overflow: visible !important;
  height: 5rem;
  width: 13rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 54%;
  left: -4.5%;
  z-index: 2;
  transform: rotate(-25deg);
}

.pianoArtist {
  padding: 1rem;
  overflow: visible !important;
  height: 5rem;
  width: 13rem;
  border-radius: 30px;
  background: linear-gradient(276.98deg, #A609A0 -16.42%, #962DC8 118.86%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 47%;
  left: -30%;
  z-index: 2;
}

.w-small {
  width: 100%;
}

@media (max-width: 767px) {
  .w-small {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100vw;
  }
}

.pianoshadow {
  height: 5.5rem;
  width: 13.1rem;
  position: absolute;
  top: 48%;
  left: -29%;
  background: #A214AC;
  opacity: 0.3;
  filter: blur(8px);
  border-radius: 33px;
  z-index: 1;
}

.haz {
  font-size: 1.1rem;
}

.drumsArtist {
  padding: 1rem;
  overflow: visible !important;
  height: 11rem;
  width: 11rem;
  border-radius: 30px;
  background-image: url(../src/assets/img/stageRed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: -7%;
  right: 20%;
  z-index: 0;
}
.pt-sm-lg{
  padding-top: 0px;
}
.drumsArtist2 {
  padding: 1rem;
  overflow: visible !important;
  height: 11rem;
  width: 11rem;
  border-radius: 30px;
  background-image: url(../src/assets/img/stageRed.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: -7%;
  right: -10%;
  z-index: 0;
}
.vw-100{
  width: 100vw !important;
}
.toppp{
  z-index: 99999999999999999999999999999999999999999999;
}
.overflow-visible {
  overflow: visible !important;
}

.guitar2 {
  width: auto;
  height: 60%;
  transform: rotate(-20deg);
  margin-left: -1rem;
}

.sLan{
  height: 2.1rem;
}
.piano2 {
  width: 100%;
  height: auto
}

.drums2 {
  width: auto;
  height: 110%;
}
.blurColor{
  background-color: #6260609a;
  backdrop-filter: blur(8px) !important;
}

.blurBgCar{
  background-color: #6260609a;
  backdrop-filter: blur(8px) !important;
  position: absolute;
  bottom: 0%;
  left: 0%; 
  height: 100%;
  width: 100%;
}
.blurBg3 {
  z-index: 1;
  padding: 0.9rem;
  padding-top: 1.5rem;
  text-align: left;
  font-family: 'Dela Gothic One', cursive;
  height: 7.2rem;
  width: 17rem;
  border-radius: 25px;
  background-color: #00000043;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: -15%;
  left: -3%;
}

.blurBg22 {
  z-index: 2;
  padding: 0.9rem;
  text-align: left;
  font-family: 'Dela Gothic One', cursive;
  height: 7.8rem;
  width: 17rem;
  border-radius: 25px;
  background-color: #00000043;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 20%;
  right: -14%;
}
.blurBg2 {
  z-index: 1;
  padding: 0.9rem;
  text-align: left;
  font-family: 'Dela Gothic One', cursive;
  height: 7.8rem;
  width: 17rem;
  border-radius: 25px;
  background-color: #00000043;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 20%;
  right: 4%;
}

.bubble {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s !important;
}

.bubble:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s !important;
  transform: scale(1.05) !important;
}

.text-jakarta{
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.section-title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.vertImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: "11rem";
  width: "100%";
  margin-top: "0";
  margin-left: "0";
  min-height: "11rem";
  object-fit: "cover";
  z-index: 5;
}

.py-05{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.whitesmoke-hvr {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  text-decoration: none !important;
  border-radius: 15px !important;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(245, 245, 245, 0);
}
.br-15-bottom{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.whitesmoke-hvr:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: whitesmoke;
}

.nav-link-m {
  text-decoration: none !important;
  color: #535455bf;
  padding-right: 15px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.nav-link-m:hover {
  text-decoration: none !important;
  color: #F68D1E;
  padding-right: 15px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.artistCarImg{
  min-height: 10rem;
  max-height: 25rem;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  overflow: visible;
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fNav{
  font-weight: 500;
  font-size: 1rem;
}
.patternBg {
  min-height: 18rem;
  height: 100%;
  max-height: 25rem;
  /* background-image: url(../src/assets/img/background/banner_mariiachi.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%; */
  width: 70%;
  /* overflow-x: hidden; */
  /* border-radius: 15px; */
  /* overflow: visible; */
  position: relative !important;
}

.artistCarousel {
  height: 31rem;
}
.h-100{
  height: 100% !important;
}
.h-100-md{
  height: 100% ;
}
.arImg{
  height: auto;
  width: 80%;
  max-height: 100%;
}

.arImgMob{
  /* height: 100% !important; */
  /* width: 90vw !important; */
  object-fit: cover;
}

.adSwiper{
  width: 100%;
  height: auto !important;
  min-height: 10rem;
} 
.textAd{
  font-size: 12px;
} 

.adChild{
  display: none;
}

.ad:hover > .adChild{
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
align-items: center;
justify-content: center;
background-color: #6b6a6ab7;
padding: 1rem;
color: white;
text-align: center;
}
@media (min-width: 501px) {
.swiper {
  /* margin: 100px auto; */
  width: 80%;
  height: 30rem;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
}

.locModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  position: fixed !important;
  top: 0px;
  left: 0px;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 100000;
}
.ox-hid{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.swiperSearch{
  padding: 0px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 20rem;
}

.srchImg{
  width: 100%;
  height: 100%;
  max-height: 21rem;
  object-fit: cover;
}

.searchSlide{
  background: linear-gradient(117deg, rgba(251,199,118,1) 0%, rgba(255,170,55,1) 16%, rgba(255,147,0,1) 89%);
  height: 100% !important;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;

  width: 100% !important;
}

@media (max-width: 500px) {
.swiper-cards {
  max-width: 75vw ;
  /* overflow: hidden !important; */
display: flex;
align-items: center;
justify-content: center;
  /* height: 32rem; */
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  /* max-width: 70vw !important; */

  /* font-size: 22px; */
  /* font-weight: bold; */
  /* color: #fff; */
}

.srchImg{
  position: absolute;
  top: 2rem;
  right: -10%;
 
  height: 40rem;
  object-fit: contain;

}
.swiperSearch{
  padding: 0px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 15rem;
}

.searchSlide{
  background: linear-gradient(117deg, rgba(251,199,118,1) 0%, rgba(255,170,55,1) 16%, rgba(255,147,0,1) 89%);
  height: 100% !important;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;

  width: 100% !important;
}

}





/* 
.swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
} */

@media (max-width: 700px) {
  /* .artistCarousel {
    height: 29rem;
  } */
  .h-100-md{
    height: auto;
  }
  .artistCarImg{
    margin-top: 0.1rem;
  }
  .patternBg {
    margin-top: 3rem;
  }
}

/* @media (min-width: 994px) {
  .homeCar2 {
    background-image: url(../src/assets/img/background/banner_mariiachi.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    border-radius: 15px;
  }
} */

.blurBgWel {
  background-color: #150032c5;
  backdrop-filter: blur(5px) !important;
  position: absolute;
  width: 100%;
  height: 100%;
  /* height: max-content !important; */
  padding-bottom: 5rem;
  padding-top: 3rem;
}

.d-stretch{
  min-height: 100vh;
  height: max-content;
  position: absolute;
  display: flex !important;
  align-items: stretch !important;
  flex-direction: column;
}

@media (max-width: 500px) {
  .blurBgWel {
    background-color: #150032c5;
    backdrop-filter: blur(5px) !important;
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: max-content !important;
    padding-bottom: 5rem;
    padding-top: 3rem;
  }
  
  .d-stretch{
    /* min-height: 100vw; */
    height: max-content !important;
    position: absolute;
    display: flex !important;
    align-items: stretch !important;
    flex-direction: column;
  }
}
.smooth {
  scroll-behavior: smooth;
}


.td-none {
  outline: none !important;
  text-decoration: none !important;
}

.td-none:hover {
  text-decoration: none !important;

}

.hvr-warning {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.hvr-warning:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  background-color: #1a0376;
  /* background-color: #F68D1E; */

}
.cubic{
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.arNav{
  color: #F68D1E;
  font-weight: 500;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
.arNav:hover{
  color: #000000 !important;
  background-color: whitesmoke !important;

}
.nav-link.active {
  color: #ffffff !important;
  background-color: #F68D1E !important;
}
.nav-link.disabled {
  color: #a1a1a1 !important;
}
/* .arNav:focus{
  color: #ffffff !important;
  background-color: #F68D1E !important;
}
.arNav::before{
  color: #ffffff !important;
  background-color: #F68D1E !important;
} */


.hvr-warning:hover .text-warning{
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  /* color: white !important; */
}

.hvr-warning:hover  .text-secondary :not(.secondaryY .text-primary){
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  color: white !important;
}

/* .hvr-warning:hover .inv-hvr-warning{
  background-color: #2f1597cf !important;
  color: white;
} */
.d-xss-none {
  display: block;
}

.mt-xs-2 {
  margin-top: 0px;
}
.carMar{
  height: 100%;
  width: auto;
  position: absolute;
  bottom: 10px;
  right: 0%;
}
.offCanvas {
  transition: linear 0.15s;
}
.position-relative{
  position: relative !important;
}
@media (max-width: 580px) {
  .w-sm-100-140{
    width: 100%;
  }
  .pt-sm-lg{
    padding-top: 2rem;
  }
  .mt-xs-2 {
    margin-top: 0.5rem !important;
  }
  .d-xss-none {
    display: none !important;
    visibility: hidden;
  }
}
.overflow-scroll{
  overflow: scroll !important;
}
.overflow-hidden{
  overflow: hidden !important;

}
@media (min-width: 900px) {
  .llegas{
    font-size: 2.5rem !important;
  }
  .fh-lg {
    height: 100vh;
    overflow-y: scroll;
  }
}

.bannerAuth {
  background-image: url(../src/assets/img/sombrero.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width: 100% !important;
  overflow-x: hidden;
  min-height: 100%;
}

.checkInst {
  max-height: 10rem;
  overflow-y: scroll;
}

.image>.child {
  display: none;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.image {
  display: none;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.image:hover {
  display: block;
  background-color: rgba(0, 0, 0, 0.308);
}

.image:hover>.child {
  display: block;
  background-color: rgba(0, 0, 0, 0.408);
}

.image::after {
  display: block;
  background-color: rgba(0, 0, 0, 0.408);
}

.image:active {
  display: block;
  background-color: rgba(0, 0, 0, 0.408);
}
.bg-whitesmoke{
  background-color: whitesmoke !important;
}
.bgCurveTop {
  width: 100%;
  min-height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-image: url(../src/assets/img/background/curveTop.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  z-index: 1;
  display: flex;
}

.bgCurveBottom {
  width: 100%;
  min-height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-image: url(../src/assets/img/background/curveBottom2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  z-index: 1;
  display: flex;
}


@media (max-width: 399px) {
  .mb-Art {
    height: 23vh;
  }
}


@media (max-width: 750px) {
  .mb-Art {
    height: 25vh;
  }
}

@media (min-width: 751px) {
  .mb-Art {
    height: 0vh;
  }
}



.bannergeneros {
  background-image: url(../src/assets/img/stage.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width: 100% !important;
  overflow-x: hidden;
  min-height: 35rem;
  display: flex;
}



@media (min-width: 800px) {
  .leftMenu {
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
  }

  .vidWidth {
    width: 50vw;
    height: 30rem !important;
  }
  .bannerAuth {
    background-image: url(../src/assets/img/sombrero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100% !important;
    overflow-x: hidden;
    height: 100vh;
  }

  .bannergeneros {
    display: flex;
    background-image: url(../src/assets/img/stage.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100% !important;
    overflow-x: hidden;
    transform: translateZ(1px) scale(1.1) !important;
    min-height: 35rem;
  }
}

.carousel {
  width: 100%;
  height: auto;
}

.carousel-item {
  overflow: hidden;
}

.mask-dark {
  height: 100% ;
  flex-grow: 4;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.451);
}

.mask-dark-sm {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.301);
  color: white;
}

.scale-down {
  transform: scale(0.9);
}

.mask-purp {
  flex-grow: 4;
  min-height: 35rem;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(59, 0, 71, 0.7);
}

.hvr-success {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 3s;
}

.hvr-success:hover {
  color: #00d97e;
  border-color: #00d97e;
}

.hvr-success:hover div h6 {
  color: #00d97e !important;
}

.hvr-success:hover div h5 {
  color: #00d97e !important;
}

.shadow-hvr {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  box-shadow: none;
}

.c-expand {
  height: 10rem;
  width: 10rem;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.8s;
}

.justify-content-space-between {
  display: flex;
  justify-content: space-between !important;
}

::-webkit-scrollbar {
  display: none;
}

.title-artist {
  color: black;
  font-weight: 500;
}

.br-10 {
  border-radius: 10px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.dividerOrange {
  border-bottom: 1px solid #f68e1e86;
  width: 100%;
  height: 0.1rem;
}

.divGenres {
  width: 100% !important;
  display: inline;
  padding-bottom: 0.3rem;
}

.genreVert {
  color: #F68D1E;
  font-weight: 500;
}

.favVert {
  padding-top: 0.1rem;
  color: #49B14D;
  font-weight: 450;
}

.c-expand-vert {
  height: 11rem;
  width: 12rem;
}
.c-expand>.child1 {
  display: none !important;
}
.c-expand-vert>.child1 {
  display: none !important;
}

.musicoBanner {
  background:
    linear-gradient(117deg, rgba(251, 199, 118, 1) 0%, rgba(255, 170, 55, 1) 16%, rgba(255, 147, 0, 1) 89%);
  color: white;
  border-radius: 15px;
}

.mh-100 {
  min-height: 100vh;
}

.c-expand:hover,
.c-expand:focus,
.c-expand:active {
  width: 24rem !important;
}

.child0 {
  display: block;
}

.c-expand-vert:hover>.child0 {
  display: none !important;
}

.c-expand:hover>.child1 {
  display: inherit !important;
}

.c-expand-vert:hover,
.c-expand-vert:focus,
.c-expand-vert:active {
  height: 100% !important;
}

.border-none {
  border: #ffffff00 solid 0px !important;
}

.c-expand-vert:hover>.child1 {
  display: inherit !important;
}

.shadow-hvr:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.hvr-light {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.hvr-light:hover {
  background-color: whitesmoke;
}

.shadow-hvr:hover>div>.child {
  display: block;
}
.searchTitle{
  font-size: 1rem;
  font-weight: 600;;
  /* color: var(--orange); */
  color: black;
}
.iconGenre {
  filter: invert(100%);
  height: 5rem;
  width: 5rem;
}

.iconWhite {
  filter: invert(100%);
}

.iconGenreBlack {
  height: 4rem;
  width: 4rem;
}

.icon2Black {
  height: 3.5rem;
  width: 3.5rem;
}

.hvr-success:hover div .iconGenre {
  filter: invert(100%) hue-rotate(180deg);
}
